import {Adapter} from '../adapters/adapter';
import {Injectable} from '@angular/core';

export class Campaign {
  constructor(
    public id: number,
    public name: string,
    public createdAt: Date,
    public manager: string
  ) {  }

}

@Injectable({
  providedIn: 'root'
})
export class CampaignAdapter implements Adapter<Campaign> {
  adapt(item: any) {
    return new Campaign(
      item.id, item.name, new Date(item.createdAt), item.manager || ''
    );
  }
}
