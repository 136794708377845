import {Injectable} from '@angular/core';
import { Adapter } from '../adapters/adapter';

export class Role {
  constructor(
    public id: number,
    public description: string,
    public priority: number
  ) { }

  static availableRoles() {
    return [
      new Role(null, 'USUARIO', 1),
      new Role(null, 'SUPER USUARIO', 2),
      new Role(null, 'ADMINISTRADOR', 3)
    ];
  }
}

@Injectable({
  providedIn: 'root'
})
export class RoleAdapter implements Adapter<Role> {
  adapt(item: any) {
    return new Role(
      item.id, item.description, item.priority
    );
  }
}
