import { environment } from "../../environments/environment"
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AgmCoreModule } from '@agm/core';
import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';
import { ChartsModule } from 'ng2-charts';

import { CampaignsRoutingModule } from './campaigns-routing.module';
import { ToolbarComponent } from './campaigns-list/toolbar/toolbar.component';
import {CampaignsListComponent} from './campaigns-list/campaigns-list.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {LayoutModule} from '@angular/cdk/layout';
import {
  MatSelectModule,MatButtonModule, MatCardModule, MatCheckboxModule, MatDialogModule,
  MatGridListModule, MatInputModule, MatTableModule, MatMenuModule, MatIconModule,
} from '@angular/material';
import { SatDatepickerModule, SatNativeDateModule } from 'saturn-datepicker';
import {HttpClientModule} from '@angular/common/http';
import { NewCampaignComponent } from './new-campaign/new-campaign.component';
import { EditCampaignComponent } from './edit-campaign/edit-campaign.component';
import { CampaignDetailComponent } from './campaign-detail/campaign-detail.component';
import { AddMemberComponent } from './add-member/add-member.component';
import { RemoveMemberDialogComponent } from './remove-member-dialog/remove-member-dialog.component';
import { AddSurveyComponent } from './add-survey/add-survey.component';
import { EditSurveyComponent } from './edit-survey/edit-survey.component';
import { MapAnswersComponent } from './map-answers/map-answers.component';
import { AnswersListComponent} from './answers-list/answers-list.component';
import { ReportsComponent } from './reports/reports.component';



@NgModule({
  declarations: [
    ToolbarComponent,
    CampaignsListComponent,
    NewCampaignComponent,
    EditCampaignComponent,
    CampaignDetailComponent,
    AddMemberComponent,
    RemoveMemberDialogComponent,
    AddSurveyComponent,
    EditSurveyComponent,
    MapAnswersComponent,
    AnswersListComponent,
    ReportsComponent],
  imports: [
    CommonModule,
    CampaignsRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatSelectModule,
    MatButtonModule,
    SatDatepickerModule,
    SatNativeDateModule,
    MatTableModule,
    MatGridListModule,
    HttpClientModule,
    MatCardModule,
    MatInputModule,
    MatMenuModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatCheckboxModule,
    ChartsModule,
    MatIconModule,
    AgmCoreModule.forRoot({
      //apiKey: environment.keyGmap,
      apiKey: "AIzaSyC4CeM1zlbTLNIfhuTGP3KYADzIHqDQb_0"
    }),
    AgmJsMarkerClustererModule
  ]
})
export class CampaignsModule { }
