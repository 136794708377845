
import { NgModule } from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';

import { AppComponent } from './app.component';
import { SideNavComponent } from '../shared/side-nav/side-nav.component';
import { AppRoutingModule } from './app-routing.module';
import { CampaignsModule } from '../campaigns/campaigns.module';
import { UsersModule } from '../users/users.module';
import { LoginComponent } from '../login/login.component';
import { AlertComponent } from '../alert/alert.component';
import {JwtInterceptor} from '../interceptors/jwt.interceptor';
import {ErrorInterceptor} from '../interceptors/error.interceptor';
import {AuthBackendService} from '../auth/auth-backend.service';
import {CommonModule, HashLocationStrategy, LocationStrategy} from '@angular/common';
import {MatButtonModule, MatCardModule, MatIconModule, MatListModule, MatSidenavModule, MatToolbarModule} from '@angular/material';
import {LayoutModule} from '@angular/cdk/layout';
import {ReactiveFormsModule} from '@angular/forms';
import {AddMemberComponent} from '../campaigns/add-member/add-member.component';
import {RemoveMemberDialogComponent} from '../campaigns/remove-member-dialog/remove-member-dialog.component';
import {AddSurveyComponent} from '../campaigns/add-survey/add-survey.component';
import {EditSurveyComponent} from '../campaigns/edit-survey/edit-survey.component';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';



@NgModule({
  declarations: [
    AppComponent,
    SideNavComponent,
    LoginComponent,
    AlertComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CampaignsModule,
    HttpClientModule,
    UsersModule,
    MatSidenavModule,
    MatToolbarModule,
    LayoutModule,
    MatCardModule,
    MatListModule,
    MatIconModule,
    ReactiveFormsModule,
    MatButtonModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    AuthBackendService
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    AddMemberComponent,
    RemoveMemberDialogComponent,
    AddSurveyComponent,
    EditSurveyComponent
  ]
})
export class AppModule { }
