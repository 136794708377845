import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router';
import { map,filter } from 'rxjs/operators';
import { Observable } from 'rxjs';

import {FormControl,FormBuilder, FormGroup} from '@angular/forms';

import { Answer } from '../../answers/answer';

import {AnswerService} from '../../answers/answer.service';

@Component({
  selector: 'app-answers-list',
  templateUrl: './answers-list.component.html',
  styleUrls: ['./answers-list.component.css']
})


export class AnswersListComponent implements OnInit {
  columns: Array<any> = [
    { name: 'position', label: 'No.' },
    { name: 'name', label: 'Name' },
    { name: 'weight', label: 'Weight' },
    { name: 'symbol', label: 'Symbol' }
  ];
  displayedColumns: string[] = this.columns.map(column => column.name);
  answers = [];

  currentAnswer: Answer;
  surveyName: string;
  form: FormGroup;
  users: string[] = [];

  private surveyId: number;
  filters = [];
  filteredData = [];


  constructor(
    private router:Router,
    private answerService: AnswerService,
    private route: ActivatedRoute,
    fb: FormBuilder) {

    this.form = fb.group({
      date: [{begin: Date, end: Date}]
    });
  }

  ngOnInit() {
    this.surveyId = Number(this.route.snapshot.paramMap.get('id'));
    this.retrieveAnswers(this.surveyId);
  }

  retrieveAnswers(id){
    this.answerService.getAnswersBySurvey(id).subscribe((answers: Answer) => {
      this.mapData(answers);
    });
  }

  mapData(data){
    const names = {};
    this.columns = data.questions.map(item => {
      let options = item.answers.map(aOptions => aOptions.option);
      return  { "name": item.noQuestion.toString(), "label": item.question, "type": item.type, 'options': options};
    });

    this.surveyName = data.surveyName;

    this.columns.push({"name": "user", "label": "Usuario", "type": "TTextField", "options": []});
    this.displayedColumns = this.columns.map(column => column.name);

    this.answers = data.answers.map(item => {
      let datajson = {};
      item.answersJson.map(answer => {
        if (answer.type == 'TRadioButton') {
          let result = answer.answers.filter(ans => ans.selected);
          if (result.length > 0)
            datajson[answer.noQuestion] = result[0].option;
        }
        if (answer.type == 'TTextField') {
          datajson[answer.noQuestion] = answer.answers[0].selected;
        }
      });
      datajson['latitude'] =  item.latitude;
      datajson['longitude'] =  item.longitude,
      datajson['user'] = [item.user.firstName,item.user.lastName,item.user.motherLastName].join(' ');
      datajson['createdAt'] = item.createdAt;
      names[datajson['user']] = true;
      return datajson;
    });
    this.users = Object.keys(names);
    this.users.sort(function(a,b) {
        a = a.toLowerCase();
        b = b.toLowerCase();
        if( a == b) return 0;
        return a < b ? -1 : 1;
    });
    this.filteredData = this.answers;
  }

  filterByDate(range) {
    let begin = range.begin.toISOString();
    let end = range.end;
    end.setDate(end.getDate()+1);
    end = end.toISOString();
    this.onFilterChange('createdAt',[begin,end]);
  }

  filterByUser(user){
    if (user == 'all')
      this.onFilterChange('user',['']);
    else
      this.onFilterChange('user',[user]);
  }

  showMap(mapType){
    let answers = this.filteredData;
    let markers = {};
    markers['markers'] = answers.map(item => {
      return {'latitude': item.latitude, 'longitude': item.longitude, 'user':item.user, 'survey': this.surveyName}
    });
    markers['mapType'] = mapType;
    localStorage.setItem('markers', JSON.stringify(markers));
    const origin = window.location.origin;
    let url = origin + '/#/campaigns/answers/map';
    
    window.open(url,'_blank');
  }

  exportCSV(){
    let answers = this.filteredData;
    let headers = this.columns.map(column => column.label);
    headers.pop();
    let csv = answers.map(item => {
      let tmp = [];
      for (let key in item)
        if (item.hasOwnProperty(key))
          tmp.push('\"'+ item[key]+'\"');
      let row = tmp.join(',');
      return row;
    });

    let csvContent = "data:text/csv;charset=utf-8," 
    + headers.join(',') + ',latitude,longitude,Usuario,Fecha' + "\n"
    + csv.join('\n');

    let encodedUri = encodeURI(csvContent);
    let link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "respuestas.csv");
    document.body.appendChild(link);

    link.click();
  }

  onFilterChange(column, options) {
    const newFilter = {
      options,
      column
    };
    let tempFilters = Object.assign([], this.filters);
    const currentIndex = tempFilters.findIndex(item => {
      return item.column === column;
    });
    if (currentIndex >= 0) {
      tempFilters[currentIndex] = newFilter; 
    } else {
      tempFilters.push(newFilter);
    }
   
    this.filters = tempFilters;
    this.filter();
  }

  filter() {
    this.filteredData = this.answers;

    let tempFiltered = Object.assign([], this.filteredData);
    this.filters.forEach(filter => {
      if (filter.options && filter.options.length > 0) {
        tempFiltered = tempFiltered.filter((item) => {
          let matches = false;

          if (typeof item[filter.column]  !== 'undefined'){
            if (filter.column == 'createdAt'){
              if (item[filter.column] >= filter.options[0] && item[filter.column] < filter.options[1]){
                matches = true;
              }
            }else{
              filter.options.forEach(option => {
                if (item[filter.column].includes(option)) {
                  matches = true;
                }
              });
            }
          }
          return matches;
        })
      }
    });
    this.filteredData = tempFiltered;
  }

  productivityReport(type){
    let answers = this.filteredData;

    let reports = {};
    if (type == 'line'){
      let count = answers.reduce( (data, answer) => {
        data[answer.user] = answer.user;
        return data;
      } , {});

      let usersNames = Object.keys(count);
      let report = [];
      usersNames.forEach(user => {
        let answersByUser = answers.filter(answer => answer.user == user);
        let countDate = answersByUser.reduce( (data, answer) => {
          data[answer.createdAt.slice(0,10)] = (data[answer.createdAt.slice(0,10)] || 0) + 1 ;
          return data;
        } , {});
        let dates = Object.keys(countDate);
        let datesAnswers = [];
        dates.forEach((date) => {
          datesAnswers.push({"createdAt": date, "total": countDate[date]});
        });
        datesAnswers.sort(function(a,b){
          return +new Date(a.createdAt) - +new Date(b.createdAt);
        });
        let createdAts = datesAnswers.map(answer => answer.createdAt);
        let totals = datesAnswers.map(totals => totals.total);
        report.push({"user": user, "answers": {"createdAt": createdAts, 'totals': totals} });
      });
      reports = {"type": type, data: report };
    }else {

      let count = answers.reduce( (data, pound) => {
        data[pound.user] = (data[pound.user] || 0) + 1 ;
        return data;
      } , {});

      let usersNames = Object.keys(count);
      let report = [];
      usersNames.forEach((user) => {
        report.push({"user": user, "total": count[user]});
      });
      report.sort(function(a,b){
        return b.total - a.total;
      });

      let users = report.map(users => users.user);
      let totals = report.map(totals => totals.total);

      reports = {"type": type, "data": {"users": users, "totals": totals}};
    }
    localStorage.setItem('reports', JSON.stringify(reports));

    const origin = window.location.origin;
    let url = origin + '/#/campaigns/answers/reports';
    window.open(url,'_blank');
  }
}
