import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {User} from '../user';
import {UsersService} from '../users.service';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.css']
})
export class UsersListComponent implements OnInit {
  dataSource: User[] = [];
  displayedColumns: string[] = ['name', 'email', 'campaign', 'role', 'actions'];

  constructor(
    private router: Router,
    private usersService: UsersService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.getUsers();
  }

  getUsers() {
    this.usersService.getUsers().subscribe((data: User[]) => {
      this.dataSource = data;
    });
  }

  removeUser(id){
    this.usersService.removeUser(id).subscribe(deletedUser => {
      this.dataSource = this.dataSource.filter(u => u.id !== id);
      alert('Usuario eliminado correctamente');
    }, error => {
      if (error === 'Forbidden'){
        alert('No cuentas con permisos para esta acción');
      }
    });
  }

}
