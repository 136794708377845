import { Injectable } from '@angular/core';

import constants from '../configs/constants';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AuthenticationService} from '../auth/authentication.service';
import {Campaign} from './campaign';
import {User} from '../users/user';
import {Survey} from '../surveys/survey';



@Injectable({
  providedIn: 'root'
})
export class CampaignsBackendService {
  private readonly httpOptions = {};


  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService) {
    this.httpOptions = {
      headers: new HttpHeaders({
        Authorization: authenticationService.currentUserValue.token
      })
    };
  }

  getAll() {
    const url = `${constants.API_BASE}/campaigns`;
    return this.http.get(url, this.httpOptions);
  }

  getOne(id) {
    const url = `${constants.API_BASE}/campaigns/${id}`;
    return this.http.get(url, this.httpOptions);
  }

  create(campaign: Campaign) {
    const url = `${constants.API_BASE}/campaigns`;
    return this.http.post(url, campaign, this.httpOptions);
  }

  update(campaign: Campaign) {
    const url = `${constants.API_BASE}/campaigns/${campaign.id}`;
    return this.http.put(url, campaign, this.httpOptions);
  }

  members(id: number) {
    const url = `${constants.API_BASE}/campaigns/${id}/members`;
    return this.http.get(url, this.httpOptions);
  }

  addMembers(id: number, members: any[]) {
    const url = `${constants.API_BASE}/campaigns/${id}/add_members`;
    return this.http.post(url, { members }, this.httpOptions);
  }

  removeMember(campaignId: number, userId: number) {
    const url = `${constants.API_BASE}/users/remove_from_campaign`;
    return this.http.post(url, { campaignId, userId }, this.httpOptions);
  }

  surveys(id: number) {
    const url = `${constants.API_BASE}/campaigns/${id}/surveys`;
    return this.http.get(url, this.httpOptions);
  }

  addSurvey(id: number, survey: Survey) {
    const url = `${constants.API_BASE}/campaigns/${id}/add_survey`;
    const data =  { version: survey.version, surveyJson: survey.surveyJson, questionsJson: survey.questionsJson };
    return this.http.post(url, data, this.httpOptions);
  }

}
