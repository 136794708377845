import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UsersRoutingModule } from './users-routing.module';
import {UsersListComponent} from './users-list/users-list.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {LayoutModule} from '@angular/cdk/layout';
import {
  MatButtonModule,
  MatCardModule, MatGridListModule,
  MatIconModule, MatInputModule,
  MatListModule, MatRadioModule,
  MatSidenavModule,
  MatTableModule,
  MatToolbarModule
} from '@angular/material';
import {HttpClientModule} from '@angular/common/http';
import {CampaignsRoutingModule} from '../campaigns/campaigns-routing.module';
import { NewUserComponent } from './new-user/new-user.component';
import { UsersToolbarComponent } from './users-toolbar/users-toolbar.component';
import { EditUserComponent } from './edit-user/edit-user.component';
import { ChangePasswordComponent } from './change-password/change-password.component';


@NgModule({
  declarations: [UsersListComponent, NewUserComponent, UsersToolbarComponent, EditUserComponent, ChangePasswordComponent],
  imports: [
    CommonModule,
    UsersRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatTableModule,
    MatGridListModule,
    HttpClientModule,
    MatCardModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatRadioModule
  ]
})
export class UsersModule { }
