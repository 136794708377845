import { Injectable } from '@angular/core';
import {SurveysBackendService} from './surveys-backend.service';
import {Survey, SurveyAdapter} from './survey';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SurveysService {

  constructor(
    private api: SurveysBackendService,
    private adapter: SurveyAdapter
  ) { }

  updateSurvey(survey: Survey): Observable<Survey> {
    return this.api.update(survey).pipe(
      map((data: any) => this.adapter.adapt(data))
    );
  }
}
