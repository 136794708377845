import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AuthenticationService} from '../auth/authentication.service';
import {Survey} from './survey';
import constants from '../configs/constants';

@Injectable({
  providedIn: 'root'
})
export class SurveysBackendService {
  private readonly httOptions = {};

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService
  ) {
    this.httOptions = {
      headers: new HttpHeaders({
        Authorization: authenticationService.currentUserValue.token
      })
    };
  }

  update(survey: Survey) {
    const url = `${constants.API_BASE}/surveys/${survey.id}`;
    const data = {
      version: survey.version,
      surveyJson: survey.surveyJson,
      questionsJson: survey.questionsJson
    };
    return this.http.put(url, data, this.httOptions);
  }
}
