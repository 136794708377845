import { Component, OnInit } from '@angular/core';
import {CampaignService} from '../campaign.service';
import { Campaign } from '../campaign';

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

@Component({
  selector: 'app-campaigns-list',
  templateUrl: './campaigns-list.component.html',
  styleUrls: ['./campaigns-list.component.css']
})
export class CampaignsListComponent implements OnInit {
  displayedColumns: string[] = ['name', 'createdAt', 'id'];
  dataSource: Campaign[] = [];

  constructor(private campaignService: CampaignService) {
  }

  ngOnInit() {
    this.campaignService.getCampaigns().subscribe((data: Campaign[]) => {
      this.dataSource = data;
    });
  }

}
