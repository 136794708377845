import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UsersService} from '../users.service';
import {Router} from '@angular/router';
import {User} from '../user';
import {Role} from '../../roles/role';

@Component({
  selector: 'app-new-user',
  templateUrl: './new-user.component.html',
  styleUrls: ['./new-user.component.css']
})
export class NewUserComponent implements OnInit {
  loading = false;
  submitted = false;
  userForm: FormGroup;
  private roleNumber: number;
  availableRoles = Role.availableRoles();

  constructor(
    private router: Router,
    private userService: UsersService,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.userForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      motherLastName: [''],
      email: ['', Validators.required],
      password: ['', Validators.required],
      roleNumber: [1, Validators.required]
    });
  }

  get f() {
    return this.userForm.controls;
  }

  onSubmit() {
    this.submitted = true;

    if (this.userForm.invalid) {
      return;
    }

    this.loading = true;
    const user = {
      email: this.f.email.value, firstName: this.f.firstName.value,
      lastName: this.f.lastName.value, motherLastName: this.f.motherLastName.value,
      role: this.f.roleNumber.value, password: this.f.password.value
    };
    this.userService.saveUser(user).subscribe(savedUser => {
      this.router.navigate(['/users']);
    }, error => {
      alert(error);
      this.loading = false;
    });


  }

}
