import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import constants from '../configs/constants';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthBackendService {

  constructor(private http: HttpClient) { }

  login(email: string, password: string): Observable<any> {
    const url = `${constants.API_BASE}/auth`;
    return this.http.post<any>(url, { email, password });
  }
}
