import {Component, ElementRef, Input, OnDestroy, OnInit} from '@angular/core';
import {UsersService} from '../../users/users.service';
import {Router} from '@angular/router';
import {User} from '../../users/user';
import {MatDialogRef} from '@angular/material';
import {FormArray, FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-add-member',
  templateUrl: './add-member.component.html',
  styleUrls: ['./add-member.component.css']
})
export class AddMemberComponent implements OnInit, OnDestroy {
  nonMembers: User[] = [];
  private membersObserver: Observable<User[]>;
  form: FormGroup;
  ready = false;


  constructor(
    private userService: UsersService,
    private dialogRef: MatDialogRef<AddMemberComponent>,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.retrieveNonMembers();
  }

  ngOnDestroy(): void {

  }

  retrieveNonMembers() {
    this.membersObserver = this.userService.nonMembers().pipe();
    this.membersObserver.subscribe(users => {
      this.nonMembers = users;
      const controls = this.nonMembers.map(item => new FormControl(false) );
      this.form = this.fb.group({
        members: new FormArray(controls),
      });
      this.ready = true;
    });
  }

  get formData() {
    return  this.form.get('members') as FormArray;
  }


  save() {
    const result = [];
    this.form.controls.members.value.forEach((control, index) => {
      if (control === true) {
        result.push(this.nonMembers[index]);
      }
    });
    this.dialogRef.close(result);
  }

  close() {
    this.dialogRef.close();
  }


}
