import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {User, UserAdapter} from '../users/user';
import {AuthBackendService} from './auth-backend.service';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  public currentUser: Observable<User>;
  public currentUserSubject: BehaviorSubject<User>;

  constructor(private authBackend: AuthBackendService, private userAdapter: UserAdapter) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  login(email: string, password: string) {
    return this.authBackend.login(email, password).pipe(map(data => {
      if (data.user && data.token) {
        const user = this.userAdapter.adapt(data.user);
        user.token = data.token;
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject.next(user);
      }
      return data;
    }));
  }

  logout() {
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);

  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }
}
