import {Adapter} from '../adapters/adapter';
import {Injectable} from '@angular/core';

export class Answer {
  constructor(
    public surveyName: string,
    public questions: [],
    public answers: []
  ) {}
}

@Injectable({
  providedIn: 'root'
})
export class AnswerAdapter implements Adapter<Answer> {
  adapt(item: any) {
    return new Answer(
      item.name || '',
      item.questions || [],
      item.answers || []
    );
  }
}
