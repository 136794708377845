import { Injectable } from '@angular/core';
import {UsersBackendService} from './users-backend.service';
import {Observable} from 'rxjs';
import {User, UserAdapter} from './user';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(
    private api: UsersBackendService,
    private adapter: UserAdapter
  ) { }

  getUsers(): Observable<User[]> {
    return this.api.getAll().pipe(
      map((data: any[]) => data.map(item => this.adapter.adapt(item)))
    );
  }

  getOneuser(id) {
    return this.api.getOne(id).pipe(
      map((item: any) => this.adapter.adapt(item))
    );
  }

  saveUser(user: any) {
    return this.api.create(user).pipe(
      map((createdUser: any) => this.adapter.adapt(createdUser))
    );
    }

  updateUserInfo(id: number, user: any) {
    return this.api.update(user, id).pipe(
      map((updatedUser: any) => this.adapter.adapt(updatedUser))
    );
  }

  updatePassword(id: number, user: any){
    return this.api.updatePassword(id,user).pipe(
      map((updatePassword: any) => this.adapter.adapt(updatePassword))
    );
  }

  nonMembers(): Observable<User[]> {
    return this.api.nonMembers().pipe(
      map((users: any[]) => users.map(item => this.adapter.adapt(item)))
    );
  }

  removeUser(id: number){
    return this.api.remove(id).pipe(
      map((updatePassword: any) => this.adapter.adapt(updatePassword))
    );
  }
}
