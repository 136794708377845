import {Component, Input, OnInit} from '@angular/core';
import {Campaign} from '../campaign';
import {CampaignService} from '../campaign.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {flatMap} from 'tslint/lib/utils';
import {mergeMap, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-edit-campaign',
  templateUrl: './edit-campaign.component.html',
  styleUrls: ['./edit-campaign.component.css']
})
export class EditCampaignComponent implements OnInit {
  campaign: Observable<Campaign>;
  campaignForm: FormGroup;
  private campaignModel: Campaign;
  submitted = false;
  loading = false;

  constructor(
    private campaignService: CampaignService,
    private router: Router,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.campaignForm = this.fb.group({
      name: ['', Validators.required]
    });
    this.initialize();
  }

   initialize() {
     const id = this.activatedRoute.snapshot.paramMap.get('id');
     this.campaign = this.campaignService.getOneCampaign(id).pipe(
       tap(campaign => this.campaignForm.patchValue(campaign))
     );
     this.campaign.subscribe(campaign => this.campaignModel = campaign);

  }

  get f() {
    return this.campaignForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.campaignForm.invalid) {
      return;
    }

    this.loading = true;
    this.campaignModel.name = this.f.name.value;
    this.campaignService.saveCampaign(this.campaignModel).subscribe((updatedCampaign) => {
      this.router.navigate(['/campaigns']);
    }, error => {
      this.loading = false;
      alert(error);
    });


  }

}
