import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '../auth/auth.guard';
import {LoginComponent} from '../login/login.component';

const appRoutes: Routes = [
  { path: '', redirectTo: '/campaigns', pathMatch: 'full', canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent },
];


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(appRoutes, { enableTracing: true })
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
