import { Component, OnInit } from '@angular/core';

import { AnswerService } from '../../answers/answer.service';
import { Answer} from '../../answers/answer';

@Component({
  selector: 'app-map-answers',
  templateUrl: './map-answers.component.html',
  styleUrls: ['./map-answers.component.css']
})

export class MapAnswersComponent implements OnInit {
  location: Location;
  otherLoc: Location;
  total_questionary = 0;
  mapType;
  constructor(
    private answerService: AnswerService
  ) { }

  ngOnInit() {
    let data = JSON.parse(localStorage.getItem('markers'));
    this.mapType = data.mapType;
    this.setLocation(data.markers);
  }

  setLocation(data){
    this.location = {
      latitude: Number(data[0].latitude),
      longitude: Number(data[0].longitude),
      zoom: 8,
      markers:[]
    };
    data.map(answer => {
      this.addMarker(answer.latitude,answer.longitude,answer.survey, answer.user);
      this.total_questionary += 1;
    });
  }

  addMarker(lat: number, lng: number, surveyName:string, user: string) {
    this.location.markers.push({
      lat,
      lng,
      surveyName,
      user
    })
  }
}

interface Marker {
  lat: number;
  lng: number;
  surveyName: string,
  user: string
}

interface Location {
  latitude: number;
  longitude: number;
  zoom: number;
  markers: Array<Marker>;
}