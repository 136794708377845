import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UsersService} from '../users.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {Observable} from 'rxjs';

import { MustMatch } from './_helpers/must-match.validator';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  loading = false;
  submitted = false;
  userForm: FormGroup;
  private userId: number;
  constructor(
    private router: Router,
    private userService: UsersService,
    private fb: FormBuilder,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.userForm = this.fb.group({
      newPassword: ['', [Validators.required,
      Validators.minLength(6)]],
      confirmPassword: ['', Validators.required]
    },{
       validator: MustMatch('newPassword', 'confirmPassword')
    });
    this.userId =  Number(this.route.snapshot.paramMap.get('id'));
  }
  
   /* Handle form errors in Angular 8 */
  public errorHandling = (control: string, error: string) => {
    return this.userForm.controls[control].hasError(error);
  }

  get f() {
    return this.userForm.controls;
  }

  onSubmit() {
    this.submitted = true;

    if (this.userForm.invalid) {
      return;
    }

    this.loading = true;
    let userInfo = {
      newPassword: this.f.newPassword.value
    };

    this.userService.updatePassword(this.userId, userInfo).subscribe(savedUser => {
      this.router.navigate(['/users']);
      alert("Contraseña Actualizada");
    }, error => {
      if (error == 'Forbidden')
        alert("Su contraseña actual no coincide");
      this.loading = false;
    });

  }

}
