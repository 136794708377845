import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-edit-survey',
  templateUrl: './edit-survey.component.html',
  styleUrls: ['./edit-survey.component.css']
})
export class EditSurveyComponent implements OnInit {
  form: FormGroup;
  submitted = false;
  loading = false;
  private survey: any;

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<EditSurveyComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.survey = data;
  }

  ngOnInit() {
    this.form = this.fb.group({
      version: [this.survey.version, Validators.required],
      surveyJson: [JSON.stringify(this.survey.surveyJson), Validators.required],
      questionsJson: [JSON.stringify(this.survey.questionsJson), Validators.required]
    });
  }

  get f() {
    return this.form.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    this.loading = true;
    this.survey.version = this.f.version.value;
    this.survey.surveyJson = JSON.parse(this.f.surveyJson.value.toString().replace(/\r?\n|\r/g, ''));
    this.survey.questionsJson = JSON.parse(this.f.questionsJson.value.toString().replace(/\r?\n|\r/g, ''));
    this.dialogRef.close(this.survey);
  }

  close() {
    this.dialogRef.close();
  }

}
