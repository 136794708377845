import { Injectable } from '@angular/core';
import { AnswersBackendService } from './answers-backend.service';
import { Answer, AnswerAdapter} from './answer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AnswerService {

  constructor(
    private api: AnswersBackendService,
    private adapter: AnswerAdapter
  ) { }

  getAnswersBySurvey(id): Observable<Answer>{
    return this.api.getOne(id).pipe(
      map((item: any) => this.adapter.adapt(item))
    );
  }

  downloadAnswers(): Observable<Blob> {
    return this.api.download();
  }
}
