import { Injectable } from '@angular/core';

import constants from '../configs/constants';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from '../auth/authentication.service';
import { Answer } from './answer';

@Injectable({
  providedIn: 'root'
})
export class AnswersBackendService {
  private readonly httpOptions = {};

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService) {
    this.httpOptions = {
      headers: new HttpHeaders({
        Authorization: authenticationService.currentUserValue.token
      })
    };
  }

  getAll() {
    const url = `${constants.API_BASE}/answers/export`;
    return this.http.get(url, this.httpOptions);
  }

  getOne(id) {
    const url = `${constants.API_BASE}/answers/${id}`;
    return this.http.get(url, this.httpOptions);
  }
  download() {
    const url = `${constants.API_BASE}/answers/download`;
    const newhttpOptions = {
      headers: new HttpHeaders({
        Authorization: this.authenticationService.currentUserValue.token,
      })
    };
    return this.http.get(url, {responseType: 'blob'});
  }
}
