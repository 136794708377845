import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UsersService} from '../users.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {User} from '../user';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.css']
})
export class EditUserComponent implements OnInit {
  userInfo = {};
  userForm: FormGroup;
  submitted = false;
  loading = false;
  user: Observable<User>;
  private userId: number;

  constructor(
    private router: Router,
    private userService: UsersService,
    private fb: FormBuilder,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.userForm = this.fb.group({
      email: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      motherLastName: [''],
    });

    this.initialize();
  }

  initialize() {
    this.userId =  Number(this.route.snapshot.paramMap.get('id'));
    this.user = this.userService.getOneuser(this.userId).pipe(
      tap(user => {
        this.userInfo = {
          email: user.email, firstName: user.firstName,
          lastName: user.lastName, motherLastName: user.motherLastName
        };
        this.userForm.patchValue(this.userInfo);
      })
    );
  }

  get f() {
    return this.userForm.controls;
  }

  onSubmit() {
    this.submitted = true;

    if (this.userForm.invalid) {
      return;
    }
    this.loading = true;
    this.userInfo = {
      email: this.f.email.value,
      firstName: this.f.firstName.value,
      lastName: this.f.lastName.value,
      motherLastName: this.f.motherLastName.value
    };

    this.userService.updateUserInfo(this.userId, this.userInfo).subscribe(user => {
      this.router.navigate(['/users']);
    }, error => {
      alert(error);
      this.loading = false;
    });

  }

}
