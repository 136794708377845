import { Injectable } from '@angular/core';
import { CampaignsBackendService } from './campaigns-backend.service';
import { Campaign, CampaignAdapter } from './campaign';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {User, UserAdapter} from '../users/user';
import {Survey, SurveyAdapter} from '../surveys/survey';

@Injectable({
  providedIn: 'root'
})
export class CampaignService {

  constructor(
    private api: CampaignsBackendService,
    private adapter: CampaignAdapter,
    private userAdapter: UserAdapter,
    private surveyAdapter: SurveyAdapter
  ) { }

  getCampaigns(): Observable<Campaign[]> {
    return this.api.getAll().pipe(
      map((data: any[]) => data.map((item: any) => this.adapter.adapt(item)))
    );
  }

  getOneCampaign(id) {
    return this.api.getOne(id).pipe(
      map((item: any) => this.adapter.adapt(item))
    );
  }

  saveCampaign(campaign: Campaign) {
    if (campaign.id == null) {
      return this.api.create(campaign).pipe(
        map((createdCampaign: Campaign) => this.adapter.adapt(createdCampaign))
      );
    } else {
      return this.api.update(campaign).pipe(
        map((updatedCampaign: Campaign) => this.adapter.adapt(updatedCampaign))
      );
    }
  }

  getMembers(id: number): Observable<User[]> {
    return this.api.members(id).pipe(
      map( (data: any[]) => data.map( (item: any) => this.userAdapter.adapt(item) ) )
    );
  }

  addMembers(id: number, members: any[]) {
    return this.api.addMembers(id, members);
  }

  removeMember(campaignId: number, userId: number) {
    return this.api.removeMember(campaignId, userId);
  }

  getSurveys(id: number): Observable<Survey[]> {
    return this.api.surveys(id).pipe(
      map((data: any[]) => data.map((item: any) => this.surveyAdapter.adapt(item)))
    );
  }

  addSurvey(id: number, survey: Survey): Observable<Survey> {
    return this.api.addSurvey(id, survey).pipe(
      map((data: any) => this.surveyAdapter.adapt(data))
    );
  }

}
