import { Component, OnInit, ViewChild } from '@angular/core';

import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, Label } from 'ng2-charts';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {
  chartData: ChartDataSets[];
  chartLabels: Label[];
  chartColors: Color[];
  chartLegend = true;
  chartPlugins = [];
  chartType = 'horizontalBar';
  chartHeight = 400;
  chartOption = { 
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true
        }
      }],
    }
  };
  horizontalChartOption = { 
    responsive: true,
    maintainAspectRatio: false,
     scales: {
      xAxes: [{
        ticks: {
          beginAtZero: true
        }
      }]
    }
  };
  data = [];
  constructor() { }

  ngOnInit() {
    let answers = JSON.parse(localStorage.getItem('reports'));
    if(answers.type == 'line'){
      this.chartType = answers.type;

      let charData: ChartDataSets[];

      this.data = answers.data.map( e => {
        charData = [{ data: e.answers.totals, label: 'Cuestionarios realizados' }];
        return {"user": e.user, "chartData": charData, "labels": e.answers.createdAt};
      });
    }else{
      if (answers.data.users.length > 10){
        this.chartHeight = answers.data.users.length * 25 ;
      }else{
        this.chartHeight = 150;
      }
      this.chartData = [{data: answers.data.totals, label: 'Cuestionarios realizados' }];
      this.chartLabels = answers.data.users;
    }
    this.chartColors = [
        {
          borderColor: 'black',
          backgroundColor: 'rgba(44, 130, 201, 1)'
        }
      ];
  }
}
