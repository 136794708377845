import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import constants from '../configs/constants';
import {Observable} from 'rxjs';
import {Campaign} from '../campaigns/campaign';
import {AuthenticationService} from '../auth/authentication.service';
import {User} from './user';



@Injectable({
  providedIn: 'root'
})
export class UsersBackendService {
  private httpOptions;
  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService
    ) {

    this.httpOptions = {
      headers: new HttpHeaders({
        Authorization: authenticationService.currentUserValue.token
      })
    };
  }

  getAll(): Observable<any> {
    const url = `${constants.API_BASE}/users`;
    return this.http.get(url, this.httpOptions);
  }

  getOne(id) {
    const url = `${constants.API_BASE}/users/${id}`;
    return this.http.get(url, this.httpOptions);
  }

  create(user: any) {
    const url = `${constants.API_BASE}/users`;
    return this.http.post(url, user, this.httpOptions);
  }

  update(user: any, id: number) {
    const url = `${constants.API_BASE}/users/${user.id || id}`;
    return this.http.put(url, user, this.httpOptions);
  }

  updatePassword(id: number, user: any){
    const url = `${constants.API_BASE}/users/${id}/change_password`;
    return this.http.put(url, user, this.httpOptions);
  }

  nonMembers(): Observable<any> {
    const url = `${constants.API_BASE}/users/non_members`;
    return this.http.get(url, this.httpOptions);
  }

  remove(id: number){
    const url = `${constants.API_BASE}/users/${id}`;
    return this.http.delete(url,this.httpOptions);
  }
}
