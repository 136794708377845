import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CampaignsListComponent } from './campaigns-list/campaigns-list.component';
import {AuthGuard} from '../auth/auth.guard';
import {NewCampaignComponent} from './new-campaign/new-campaign.component';
import {EditCampaignComponent} from './edit-campaign/edit-campaign.component';
import {CampaignDetailComponent} from './campaign-detail/campaign-detail.component';
import {MapAnswersComponent} from './map-answers/map-answers.component';
import {AnswersListComponent} from './answers-list/answers-list.component';
import {ReportsComponent} from './reports/reports.component';


const routes: Routes = [
  { path: 'campaigns', component: CampaignsListComponent, canActivate: [AuthGuard] },
  { path: 'campaigns/new', component: NewCampaignComponent, canActivate: [AuthGuard] },
  { path: 'campaigns/:id', component: CampaignDetailComponent, canActivate: [AuthGuard] },
  { path: 'campaigns/:id/edit', component: EditCampaignComponent, canActivate: [AuthGuard] },
  { path: 'campaigns/answers/map', component: MapAnswersComponent, canActivate: [AuthGuard] },
  { path: 'campaigns/survey/answers/:id', component: AnswersListComponent, canActivate: [AuthGuard] },
  { path: 'campaigns/answers/reports', component: ReportsComponent, canActivate: [AuthGuard] },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CampaignsRoutingModule { }
