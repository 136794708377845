import {Role} from '../roles/role';
import {Injectable} from '@angular/core';
import {Adapter} from '../adapters/adapter';
import { RoleAdapter } from '../roles/role';
import {Campaign, CampaignAdapter} from '../campaigns/campaign';

export class User {
  public token: string;

  constructor(
    public id: number,
    public email: string,
    public firstName: string,
    public lastName: string,
    public motherLastName: string,
    public blocked: boolean,
    public role: Role,
    public campaign: Campaign

  ) {}

  fullName(): string {
    return `${this.firstName} ${this.lastName} ${this.motherLastName || ''}`;
  }
}

@Injectable({
  providedIn: 'root'
})
export class UserAdapter implements Adapter<User> {
  constructor(private roleAdapter: RoleAdapter, private campaignAdapter: CampaignAdapter) { }

  adapt(item: any) {
    return new User(
      item.id, item.email, item.firstName, item.lastName, item.motherLastName,
      item.blocked,
      (item.role != null) ? this.roleAdapter.adapt(item.role) : null,
      (item.currentCampaign != null) ? this.campaignAdapter.adapt(item.currentCampaign) : null
  );
  }
}
