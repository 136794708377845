import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialogRef} from '@angular/material';
import {Survey} from '../../surveys/survey';

@Component({
  selector: 'app-add-survey',
  templateUrl: './add-survey.component.html',
  styleUrls: ['./add-survey.component.css']
})
export class AddSurveyComponent implements OnInit {
  form: FormGroup;
  loading = false;
  submitted = false;


  constructor(
    private fb: FormBuilder,
    private dialog: MatDialogRef<AddSurveyComponent>
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      version: ['1', Validators.required],
      surveyJson: ['', Validators.required],
      questionsJson: ['', Validators.required]
    });
  }

  get f() {
    return this.form.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    this.loading = true;
    const survey = new Survey(null, '',
      JSON.parse(this.f.surveyJson.value.toString().replace(/\r?\n|\r/g, '')),
      JSON.parse(this.f.questionsJson.value.toString().replace(/\r?\n|\r/g, '')),
      null, this.f.version.value, null);
    this.dialog.close(survey);
  }

  close() {
    this.dialog.close();
  }

}
