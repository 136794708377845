 import { Component, OnInit } from '@angular/core';
import {Observable} from 'rxjs';
import {Campaign} from '../campaign';
import {CampaignService} from '../campaign.service';
import {ActivatedRoute, Router} from '@angular/router';
import {User} from '../../users/user';
import {tap} from 'rxjs/operators';
import {ModalService} from '../../modal/modal.service';
import {MatDialog} from '@angular/material';
import {AddMemberComponent} from '../add-member/add-member.component';
import {RemoveMemberDialogComponent} from '../remove-member-dialog/remove-member-dialog.component';
import {Survey} from '../../surveys/survey';
import {AddSurveyComponent} from '../add-survey/add-survey.component';
import {EditSurveyComponent} from '../edit-survey/edit-survey.component';
import {SurveysService} from '../../surveys/surveys.service';
import {AnswerService} from '../../answers/answer.service';
import {Answer} from '../../answers/answer';

@Component({
  selector: 'app-campaign-detail',
  templateUrl: './campaign-detail.component.html',
  styleUrls: ['./campaign-detail.component.css']
})
export class CampaignDetailComponent implements OnInit {

  campaignObserver: Observable<Campaign>;
  campaign: Campaign;
  private campaignId: number;
  members: User[];
  surveys: Survey[];
  membersColumns: string[] = ['name', 'email', 'role', 'id'];
  surveysColumns: string[] = ['name', 'version', 'createdAt', 'id'];

  constructor(
    private campaignService: CampaignService,
    private surveyService: SurveysService,
    private answerService: AnswerService,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: ModalService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.campaignId = Number(this.route.snapshot.paramMap.get('id'));
    this.retrieveCampaign();
    this.retrieveMembers();
    this.retrieveSurveys();
  }

  retrieveCampaign() {
    this.campaignObserver = this.campaignService.getOneCampaign(this.campaignId).pipe();
    this.campaignObserver.subscribe(campaign => {
      this.campaign = campaign;
    });
  }

  retrieveMembers() {
    this.campaignService.getMembers(this.campaignId).subscribe((members: User[]) => {
      this.members = members;
    });
  }

  retrieveSurveys() {
    this.campaignService.getSurveys(this.campaignId).subscribe((surveys: Survey[]) => {
      this.surveys = surveys;
    });
  }

  addMember() {
    const dialogRef = this.dialog.open(AddMemberComponent);
    dialogRef.afterClosed().subscribe(selectedUsers => {
      if (selectedUsers && selectedUsers.length > 0) {
        const usersIds = selectedUsers.map(user => user.id);
        this.campaignService.addMembers(this.campaignId, usersIds).subscribe(result => {
          this.retrieveMembers();
        });
      }
    }, error => {
      alert(error);
    });
  }

  removeMember(member) {
    const dialogRef = this.dialog.open(RemoveMemberDialogComponent);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.campaignService.removeMember(this.campaignId, member.id).subscribe(() => {
          this.retrieveMembers();
        }, error => {
          alert(error);
        });
      }
    });
  }

  addSurvey() {
    const config = {
      panelClass: 'material-dialog',
      width: '80vw',
      minWidth: '80vw',
      height: '75vh',
      minHeight: '75vh'

    };
    const dialogRef = this.dialog.open(AddSurveyComponent, config);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.campaignService.addSurvey(this.campaignId, result).subscribe(saveSurvey => {
            this.retrieveSurveys();
        }, error => {
          alert(error);
        });
      }
    });
  }

  editSurvey(survey) {
    const config = {
      data: survey,
      width: '80vw',
      minWidth: '80vw',
      height: '75vh',
      minHeight: '75vh'
    };
    const dialogRef = this.dialog.open(EditSurveyComponent, config);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.surveyService.updateSurvey(survey).subscribe(() => {
          this.retrieveSurveys();
        }, error => {
          alert(error);
        });
      }
    });
  }

}
