import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-users-toolbar',
  templateUrl: './users-toolbar.component.html',
  styleUrls: ['./users-toolbar.component.css']
})
export class UsersToolbarComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
