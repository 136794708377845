import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Campaign} from '../campaign';
import {Router} from '@angular/router';
import {CampaignService} from '../campaign.service';

@Component({
  selector: 'app-new-campaign',
  templateUrl: './new-campaign.component.html',
  styleUrls: ['./new-campaign.component.css']
})
export class NewCampaignComponent implements OnInit {
  loading = false;
  campaignForm: FormGroup;
  submitted = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private campaignService: CampaignService
  ) { }

  ngOnInit() {
    this.campaignForm = this.formBuilder.group({
      name: ['', Validators.required]
    });
  }

  get f() {
    return this.campaignForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.campaignForm.invalid) {
      return;
    }

    this.loading = true;
    const campaign = new Campaign(null, this.f.name.value, null, '');
    this.campaignService.saveCampaign(campaign).subscribe((createdCampaign) => {
      this.router.navigate(['/campaigns']);
    }, error => {
      this.loading = false;
      alert(error);
    });

  }

}
