import {Adapter} from '../adapters/adapter';
import {Injectable} from '@angular/core';

export class Survey {

  constructor(
    public id: number,
    public name: string,
    public surveyJson: JSON,
    public questionsJson: JSON,
    public createdAt: Date,
    public version: number,
    public campaignId: number
  ) { }

}

@Injectable({
  providedIn: 'root'
})
export class SurveyAdapter implements Adapter<Survey> {

  adapt(item: any) {
    return new Survey(
      item.id, item.name, item.surveyJson, item.questionsJson,
      new Date(item.createdAt), item.version, item.campaignId
    );
  }

}
