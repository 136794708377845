import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {UsersListComponent} from './users-list/users-list.component';
import {NewUserComponent} from './new-user/new-user.component';
import {EditUserComponent} from './edit-user/edit-user.component';
import {ChangePasswordComponent} from './change-password/change-password.component'


const routes: Routes = [
  { path: 'users', component: UsersListComponent },
  { path: 'users/new', component: NewUserComponent },
  { path: 'users/:id/edit', component: EditUserComponent },
  { path: 'users/:id/change_password', component: ChangePasswordComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UsersRoutingModule { }
